import { decode } from 'base64-arraybuffer'
import { useUpdateFormValueOnChange } from 'hook/useUpdateFormValueOnChange'
import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppConfig } from '../context/AppConfigContext'
import { Input } from './Input'

export interface OneKeyDecryptionProps {
  cipherTextBase64: string
  accessToken?: string
}

type ConfiguratorData = {
  cipherTextBase64?: string
  accessToken?: string
}

export const OneKeyDecryption: FC<OneKeyDecryptionProps> = ({ cipherTextBase64, accessToken }) => {
  const {
    config: { oidcProviderUrl },
  } = useAppConfig()
  const [message, setMessage] = useState('')

  const { register, setValue, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      cipherTextBase64,
      accessToken,
    },
  })

  useUpdateFormValueOnChange('accessToken', accessToken, setValue)
  useUpdateFormValueOnChange('cipherTextBase64', cipherTextBase64, setValue)

  const decrypt: SubmitHandler<ConfiguratorData> = async (values) => {
    try {
      const response = await fetch(`${oidcProviderUrl}/one-key/decrypt`, {
        method: 'POST',
        headers: {
          ...(values.accessToken ? { Authorization: `Bearer ${values.accessToken}` } : {}),
        },
        body: values.cipherTextBase64 ? decode(values.cipherTextBase64) : '',
      })

      const plainText = await response.text()

      setMessage(plainText)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        setMessage(`Error handling request: ${error.message}`)
      }
    }
  }

  return (
    <fieldset>
      <legend>Decrypt on the server</legend>
      <form onSubmit={handleSubmit(decrypt)}>
        <fieldset>
          <legend>Access token</legend>
          <Input type="text" {...register('accessToken')} autoComplete="off"></Input>
        </fieldset>
        <fieldset>
          <legend>Encrypted message</legend>
          <Input type="text" {...register('cipherTextBase64')} autoComplete="off"></Input>
        </fieldset>
        <button type="submit">Decrypt</button>
      </form>
      <div>{message}</div>
    </fieldset>
  )
}
