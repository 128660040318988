import * as Tabs from '@radix-ui/react-tabs'
import { NavLink } from 'react-router-dom'
import { styled } from '../styles/stitches.config'

export const Root = Tabs.Root

export const List = styled(Tabs.List, {
  marginBlockEnd: '1rem',
  borderBlockEnd: 'solid 1px LightGray',
})

export const Trigger = styled(Tabs.Trigger, {
  minHeight: '3rem',
  paddingInline: '1rem',
  border: 'none',
  background: 'inherit',
  '&[data-state="active"]': {
    color: '$secondaryBeachBlue',
    boxShadow: 'inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor',
    fontWeight: 'bold',
  },
})

export const NavLinkTabButton = styled(NavLink, {
  display: 'inline-block',
  background: 'inherit',
  border: 'none',
  paddingBlock: '1rem',
  paddingInline: '1rem',
  textAlign: 'center',
  textDecoration: 'none',
  color: 'inherit',
  boxSizing: 'border-box',
})

export const Content = Tabs.Content
