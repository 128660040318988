import { refreshToken as doRefreshToken } from 'api/oidc'
import { RefreshTokenResponse } from 'api/types'
import { Creatable } from 'components/Creatable'
import { DisplayObject } from 'components/DisplayObject'
import { Input } from 'components/Input'
import { useSubmitAction } from 'hook/useSubmitAction'
import { useCallback, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import { Metadata } from 'types/oidc'
import { Option, Options } from 'types/select'
import { toCreatableOptions, toOption, toOptions, toValue, toValues } from 'utils/select'
import { displayErrorToast, displaySuccessToast } from 'utils/toasts'
import { dbSeedClients } from '../constants'

type ConfiguratorData = {
  clientId: Option<string>
  refreshToken?: string
  scopes: Options<Metadata['scopes_supported']>
}

export const RefreshTokenConfigurator = ({
  configuration,
  refreshToken,
  clientId,
}: {
  configuration: Metadata
  refreshToken?: string
  clientId?: string
}) => {
  const { control, register, setValue, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      clientId: toOption(clientId ?? dbSeedClients.foo),
      refreshToken,
      scopes: [],
    },
  })

  useEffect(() => {
    if (refreshToken) {
      setValue('refreshToken', refreshToken)
    }
  }, [refreshToken, setValue])

  const [result, setResult] = useState<RefreshTokenResponse | null>(null)
  const fetchToken = useCallback<SubmitHandler<ConfiguratorData>>(
    async ({ scopes, ...values }) => {
      const res = await doRefreshToken(configuration, {
        client_id: toValue(values.clientId),
        grant_type: 'refresh_token',
        refresh_token: values.refreshToken,
        scope: scopes.length ? toValues(scopes).join(' ') : undefined,
      })

      setResult(res)
      if ('error' in res) {
        displayErrorToast('Received error response!')
      } else {
        displaySuccessToast()
      }
    },
    [configuration],
  )
  const [onSubmit, isSubmitting] = useSubmitAction<ConfiguratorData>(fetchToken, displayErrorToast)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Client</legend>
          <Controller
            name="clientId"
            control={control}
            render={({ field }) => <Creatable {...field} options={toCreatableOptions(Object.values(dbSeedClients))} />}
          />
        </fieldset>
        <fieldset>
          <legend>Refresh token</legend>
          <Input type="text" {...register('refreshToken')} autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Scopes</legend>
          <Controller
            name="scopes"
            control={control}
            render={({ field }) => <Select {...field} options={toOptions(configuration.scopes_supported)} isMulti />}
          />
        </fieldset>
        <fieldset>
          <legend>Actions</legend>
          <button type="submit" disabled={isSubmitting}>
            Refresh token
          </button>
        </fieldset>
      </form>
      <hr />
      {result && (
        <div>
          <DisplayObject data={result} copyableProps={['id_token', 'access_token', 'refresh_token']} />
        </div>
      )}
    </>
  )
}
