import { Creatable } from 'components/Creatable'
import { Input } from 'components/Input'
import { Link } from 'components/Link'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { SpecialConsentType } from 'types/consent'
import { Metadata, PromptType } from 'types/oidc'
import { Option, Options } from 'types/select'
import { getConfiguredResources } from 'utils/configuration'
import { getAuthUrl } from 'utils/oidc'
import { toCreatableOptions, toOption, toValue, toValues } from 'utils/select'
import { ROUTES, dbSeedClients } from '../../../constants'

type ConfiguratorData = {
  claims?: Options<Metadata['claims_supported']>
  scopes: Options<Metadata['scopes_supported']>
  returnUrl: string
  clientId: Option<string>
  resources?: Options<string[]>
  accessToken?: string
  description?: string
  consentType?: Option<SpecialConsentType | null>
  itemsToSign?: string
  prompts?: Options<string[]>
}

const defaultValues: ConfiguratorData = {
  scopes: ['openid', 'profile', 'email', 'id-bhr-full'].map((s) => ({ value: s, label: s })),
  returnUrl: `${window.location.origin}${ROUTES.redirectHandler}`,
  clientId: toOption(dbSeedClients.foo),
  consentType: toOption(null),
  itemsToSign: `{"doc1id":{"name":"Document1.pdf","value":"bd0fdd1c5b7419e0441c17215fd1ed387c67d926038a8fb04f99df9d1a2bcc96"},"doc2id":{"name":"Document With A Space.pdf","value":"4079c45796b109a7c0f2ac3961fdfe5121c3fcbacc80424422b09dcd633d7d97"}}`,
}

export const AuthUrlConfigurator = ({ configuration }: { configuration: Metadata }) => {
  const { control, watch, register } = useForm<ConfiguratorData>({
    defaultValues,
  })

  const values = watch()
  const url = getAuthUrl(configuration.authorization_endpoint, {
    scopes: values.scopes.map((s) => s.value),
    claims: values.claims?.map((c) => c.value),
    returnUrl: values.returnUrl,
    clientId: toValue(values.clientId),
    resources: values.resources?.map((r) => r.value),
    accessToken: values.accessToken,
    description: values.description,
    consentType: values.consentType?.value ?? undefined,
    itemsToSign: (values.consentType?.value === SpecialConsentType.Sign && values.itemsToSign) || undefined,
    prompt: values.prompts && toValues(values.prompts),
  })

  return (
    <form>
      <fieldset>
        <legend>Client</legend>
        <Controller
          name="clientId"
          control={control}
          render={({ field }) => <Creatable {...field} options={toCreatableOptions(Object.values(dbSeedClients))} />}
        />
      </fieldset>
      <fieldset>
        <legend>Return URL</legend>
        <Input type="text" {...register('returnUrl')} />
      </fieldset>
      <fieldset>
        <legend>Scopes</legend>
        <Controller
          name="scopes"
          control={control}
          render={({ field }) => (
            <Select {...field} options={configuration.scopes_supported.map((s) => ({ value: s, label: s }))} isMulti />
          )}
        />
      </fieldset>
      <fieldset>
        <legend>Claims</legend>
        <Controller
          name="claims"
          control={control}
          render={({ field }) => (
            <Select {...field} options={configuration.claims_supported.map((c) => ({ value: c, label: c }))} isMulti />
          )}
        />
      </fieldset>
      <fieldset>
        <legend>Request resources</legend>
        <Controller
          name="resources"
          control={control}
          render={({ field }) => (
            <Creatable {...field} options={toCreatableOptions(getConfiguredResources(configuration))} isMulti />
          )}
        />
      </fieldset>
      <fieldset>
        <legend>Use access token to skip login prompt</legend>
        <Input type="text" {...register('accessToken')} autoComplete="off" />
      </fieldset>
      <fieldset>
        <legend>Prompts</legend>
        <Controller
          name="prompts"
          control={control}
          render={({ field }) => (
            <Creatable {...field} options={toCreatableOptions(Object.values(PromptType))} isMulti isClearable />
          )}
        />
      </fieldset>
      <fieldset>
        <legend>Description</legend>
        <Input type="text" {...register('description')} autoComplete="off" />
      </fieldset>
      <fieldset>
        <legend>Special Consent Type</legend>
        <Controller
          name="consentType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={[
                toOption(null),
                ...Object.values(SpecialConsentType).map((value) => ({ value: value, label: value })),
              ]}
              isMulti={false}
            />
          )}
        />
      </fieldset>
      {values.consentType?.value === SpecialConsentType.Sign && (
        <fieldset>
          <legend>Items To Sign</legend>
          <Input type="text" {...register('itemsToSign')} autoComplete="off" />
        </fieldset>
      )}
      <fieldset>
        <legend>Actions</legend>
        <Link href={url.toString()} target={'_blank'}>
          Open Auth URL
        </Link>
      </fieldset>
    </form>
  )
}
