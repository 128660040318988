import { createRoot } from 'react-dom/client'
import { App } from './App'

const rootElement = document.getElementById('root')

if (!rootElement) {
  throw new Error('Failed to find the root element')
}

const root = createRoot(rootElement)

root.render(
  // TODO fix errors related to effects running twice and re-enable
  // e.g. RedirectHandler sends 2 token requests, the 2nd results in oidc error
  // <StrictMode>
  <App />,
  // </StrictMode>,
)
