import { ClaimDownloadConfigurator } from 'components/ClaimDownloadConfigurator'
import { Content, List, NavLinkTabButton, Root, Trigger } from 'components/Tabs'
import { TokenIntrospectionConfigurator } from 'components/TokenIntrospectionConfigurator'
import { useOidcConfiguration } from 'hook/useOidcConfiguration'
import { FC } from 'react'
import { Divider } from '../../components/Divider'
import { TokenExchangeConfigurator } from '../../components/TokenExchangeConfigurator'
import { ROUTES } from '../../constants'
import { AuthUrlConfigurator } from './components/AuthUrlConfigurator'
import { SamlButtons } from './components/SamlButtons'

export enum Tabs {
  AuthRequest = 'AuthRequest',
  TokenExchange = 'TokenExchange',
  TokenIntrospection = 'TokenIntrospection',
  DownloadClaim = 'DownloadClaim',
}

type Props = {
  tab: Tabs
}

export const Home: FC<Props> = ({ tab }) => {
  const { configuration, error, loading } = useOidcConfiguration()

  return (
    <div>
      {!loading && configuration && (
        <Root value={tab}>
          <List>
            <Trigger asChild value={Tabs.AuthRequest}>
              <NavLinkTabButton to={ROUTES.index}>Auth request</NavLinkTabButton>
            </Trigger>
            <Trigger asChild value={Tabs.TokenExchange}>
              <NavLinkTabButton to={ROUTES.tokenExchange}>Token exchange</NavLinkTabButton>
            </Trigger>
            <Trigger asChild value={Tabs.TokenIntrospection}>
              <NavLinkTabButton to={ROUTES.tokenIntrospection}>Token introspection</NavLinkTabButton>
            </Trigger>
            <Trigger asChild value={Tabs.DownloadClaim}>
              <NavLinkTabButton to={ROUTES.downloadClaim}>Download claim</NavLinkTabButton>
            </Trigger>
          </List>
          <Content value={Tabs.AuthRequest}>
            <AuthUrlConfigurator configuration={configuration} />
            <Divider />
            <SamlButtons />
          </Content>
          <Content value={Tabs.TokenExchange}>
            <TokenExchangeConfigurator configuration={configuration} />
          </Content>
          <Content value={Tabs.TokenIntrospection}>
            <TokenIntrospectionConfigurator configuration={configuration} />
          </Content>
          <Content value={Tabs.DownloadClaim}>
            <ClaimDownloadConfigurator configuration={configuration} />
          </Content>
        </Root>
      )}
      {loading && 'Loading configuration'}
      {error}
    </div>
  )
}
