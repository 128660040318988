import { useAppConfig } from '../../../context/AppConfigContext'
import { SamlPostLogin } from './SamlPostLogin'

export const SamlButtons = () => {
  const {
    config: { samlIpLoginUrl },
  } = useAppConfig()

  return (
    <>
      <div>
        <a target={'_blank'} href={samlIpLoginUrl}>
          SAML redirect login
        </a>
      </div>
      <div>
        <SamlPostLogin />
      </div>
    </>
  )
}
