import { forwardRef, MutableRefObject } from 'react'
import { GroupBase } from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import Select from 'react-select/dist/declarations/src/Select'

export const Creatable = forwardRef(
  <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: CreatableProps<Option, IsMulti, Group>,
    ref:
      | ((instance: Select<Option, IsMulti, Group> | null) => void)
      | MutableRefObject<Select<Option, IsMulti, Group> | null>
      | null,
  ) => <CreatableSelect placeholder="Select or create" ref={ref} {...props} />,
) as CreatableSelect
