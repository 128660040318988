import { getOidcConfiguration } from 'api/oidc'
import { useEffect, useState } from 'react'
import { Metadata } from 'types/oidc'
import { filterSupportedClaim, filterSupportedScopes } from 'utils/filter'
import { useAppConfig } from '../context/AppConfigContext'

export const useOidcConfiguration = () => {
  const {
    config: { oidcProviderUrl },
  } = useAppConfig()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<string | undefined>()
  const [configuration, setConfiguration] = useState<Metadata | undefined>()

  useEffect(() => {
    const load = async () => {
      setLoading(true)
      setConfiguration(undefined)
      setError(undefined)

      try {
        const conf = await getOidcConfiguration(oidcProviderUrl)

        conf.scopes_supported = conf.scopes_supported.filter(filterSupportedScopes)
        conf.claims_supported = conf.claims_supported.filter(filterSupportedClaim)

        setConfiguration(conf)
      } catch (e: unknown) {
        console.error(JSON.stringify(e, Object.getOwnPropertyNames(e), 2))
        setError('Cannot load oidc configuration')
      } finally {
        setLoading(false)
      }
    }
    void load()
  }, [oidcProviderUrl])

  return { loading, error, configuration }
}
