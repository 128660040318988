import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ROUTES } from './constants'
import { AppConfigProvider } from './context/AppConfigContext'
import { Home, Tabs } from './pages/Home/Home'
import { RedirectHandler } from './pages/RedirectHandler/RedirectHandler'
import { SamlResponse } from './pages/SamlResponse/SamlResponse'

export const App = () => {
  return (
    <AppConfigProvider>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path={ROUTES.redirectHandler} element={<RedirectHandler />} />
          <Route path={ROUTES.samlResult} element={<SamlResponse />} />
          <Route path={ROUTES.index} element={<Home tab={Tabs.AuthRequest} />} />
          <Route path={ROUTES.tokenExchange} element={<Home tab={Tabs.TokenExchange} />} />
          <Route path={ROUTES.tokenIntrospection} element={<Home tab={Tabs.TokenIntrospection} />} />
          <Route path={ROUTES.downloadClaim} element={<Home tab={Tabs.DownloadClaim} />} />
        </Routes>
      </Router>
    </AppConfigProvider>
  )
}
