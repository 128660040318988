import { PropertyValue, CSS as StitchesCSS, createStitches } from '@stitches/react'

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
  theme: {
    colors: {
      gray400: 'gainsboro',
      gray500: 'lightgray',
      secondaryBeachBlue: '#296693',
    },
  },
  media: {
    bp1: '(min-width: 480px)',
  },
  utils: {
    marginX: (value: PropertyValue<'backgroundColor'>) => ({ marginLeft: value, marginRight: value }),
  },
})
export type CSS = StitchesCSS<typeof config>
