import { Metadata } from 'types/oidc'
import { getClaimDownloadUrl } from 'utils/oidc'
import { toPairs } from 'utils/urlSearchParams'
import {
  ExchangeTokenAnyResponse,
  ExchangeTokenParameters,
  GetTokenParams,
  GetTokenResponse,
  RefreshTokenParameters,
  RefreshTokenResponse,
  TokenIntrospectionParameters,
  TokenIntrospectionResponse,
} from './types'

export const getOidcConfiguration = async (url: string) => {
  const response = await fetch(`${url}/.well-known/openid-configuration`)

  if (response.ok) {
    const metadata = (await response.json()) as Metadata
    return metadata
  } else {
    throw Error()
  }
}

export const getToken = async (oidcConfig: Metadata, params: GetTokenParams) => {
  const response = await fetch(
    oidcConfig.token_endpoint,

    {
      method: 'POST',
      body: new URLSearchParams(params).toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      cache: 'no-cache',
    },
  )
  return (await response.json()) as GetTokenResponse
}

export const exchangeToken = async (oidcConfig: Metadata, params: ExchangeTokenParameters) => {
  const response = await fetch(oidcConfig.token_endpoint, {
    method: 'POST',
    body: new URLSearchParams(toPairs(params)).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    cache: 'no-cache',
  })
  return (await response.json()) as ExchangeTokenAnyResponse
}

export const refreshToken = async (oidcConfig: Metadata, params: RefreshTokenParameters) => {
  const response = await fetch(oidcConfig.token_endpoint, {
    method: 'POST',
    body: new URLSearchParams(toPairs(params)).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    cache: 'no-cache',
  })
  return (await response.json()) as RefreshTokenResponse
}

export const tokenIntrospection = async (oidcConfig: Metadata, params: TokenIntrospectionParameters) => {
  const response = await fetch(oidcConfig.introspection_endpoint, {
    method: 'POST',
    body: new URLSearchParams(toPairs(params)).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    cache: 'no-cache',
  })
  return (await response.json()) as TokenIntrospectionResponse
}

export const claimDownload = async (oidcConfig: Metadata, claim: string, accessToken?: string) => {
  const url = getClaimDownloadUrl(oidcConfig.issuer, claim).href
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    },
  })
  return response
}
