import { styled } from 'styles/stitches.config'

export const Details = styled('details', {})

export const DetailsSummary = styled('summary', {
  fontWeight: 'bold',
})

export const DetailsContent = styled('div', {
  margin: '1rem',
})
