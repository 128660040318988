import { ButtonHTMLAttributes, FC, useCallback } from 'react'
import { toast } from 'react-toastify'

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  content: string | undefined
}

export const CopyToClipboardButton: FC<Props> = ({ content, children, ...rest }) => {
  const copyValueToClipboard = useCallback(async () => {
    if (content) {
      try {
        await navigator.clipboard.writeText(content)
        toast('Copied!', { type: toast.TYPE.SUCCESS, autoClose: 2000 })
      } catch {
        toast('Error copying to clipboard!', { type: toast.TYPE.ERROR })
      }
    }
  }, [content])

  return (
    <button {...rest} onClick={copyValueToClipboard}>
      {children ?? 'Copy to clipboard'}
    </button>
  )
}
