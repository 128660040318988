import { styled } from 'styles/stitches.config'

export const Link = styled('a', {
  display: 'inline-block',
  backgroundColor: '$gray400',
  borderRadius: '10px',
  fontSize: '16px',
  fontWeight: 400,
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: '16px',
  paddingRight: '16px',
  margin: '2px',
})
