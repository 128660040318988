import { isString } from 'oneid-common'
import { useMemo } from 'react'
import { CopyToClipboardButton } from './CopyToClipboardButton'
import { Code } from './SelectableText'

// https://stackoverflow.com/a/49402091
type KeysOfUnion<T> = T extends T ? keyof T : never

interface Props<T extends Partial<Record<string, unknown>>> {
  data: T
  copyableProps?: Array<KeysOfUnion<T>>
}

export function DisplayObject<T extends Partial<Record<string, unknown>>>({ data, copyableProps }: Props<T>) {
  const hasCopyButton = useMemo(() => {
    return new Set(copyableProps || [])
  }, [copyableProps])

  return (
    <div>
      {Object.entries(data).map((entry) => {
        const [key, value] = entry as [KeysOfUnion<T>, (typeof data)[KeysOfUnion<T>]]
        const stringifiedValue = isString(value) ? value : JSON.stringify(value, null, 2)

        return (
          <div key={key}>
            <strong>{key}: </strong>
            {hasCopyButton.has(key) && (
              <>
                <CopyToClipboardButton content={stringifiedValue} />
              </>
            )}{' '}
            <Code>{stringifiedValue}</Code>
          </div>
        )
      })}
    </div>
  )
}
