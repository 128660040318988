import { createHkdf, encryptEcies } from '@beyon-connect/crypto-web'
import { decode, encode } from 'base64-arraybuffer'
import { useUpdateFormValueOnChange } from 'hook/useUpdateFormValueOnChange'
import { Dispatch, FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppConfig } from '../context/AppConfigContext'
import { Input } from './Input'

export interface OneKeyEncryptionProps {
  sub?: string
  publicKeyBase64?: string
  onEncrypt: Dispatch<string>
}

type ConfiguratorData = {
  sub: string
  publicKeyBase64: string
  message: string
}

export const OneKeyEncryption: FC<OneKeyEncryptionProps> = ({ sub, publicKeyBase64, onEncrypt }) => {
  const { config } = useAppConfig()

  const { register, setValue, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      sub,
      publicKeyBase64,
      message: 'testing message',
    },
  })

  useUpdateFormValueOnChange('sub', sub ?? '', setValue)
  useUpdateFormValueOnChange('publicKeyBase64', publicKeyBase64 ?? '', setValue)

  const encrypt: SubmitHandler<ConfiguratorData> = async (values) => {
    const hkdfInfo = `${config.oneKeyHkdfPrefix}${values.sub}`
    const hkdf = createHkdf({
      hash: 'SHA-256',
      info: new TextEncoder().encode(hkdfInfo),
      crypto,
    })

    const publicKey = await crypto.subtle.importKey(
      'spki',
      decode(values.publicKeyBase64),
      { name: 'ECDH', namedCurve: 'P-521' },
      false,
      [],
    )

    const cipherText = await encryptEcies(
      {
        aesKeyLength: 256,
        namedCurve: 'P-521',
        kdf: hkdf,
      },
      publicKey,
      new TextEncoder().encode(values.message),
    )

    onEncrypt(encode(cipherText))
  }

  return (
    <fieldset>
      <legend>Encrypt locally</legend>
      <form onSubmit={handleSubmit(encrypt)}>
        <fieldset>
          <legend>Sub</legend>
          <Input type="text" {...register('sub')} required autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Public Key (base64)</legend>
          <Input type="text" {...register('publicKeyBase64')} required autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Message to encrypt</legend>
          <Input type="text" {...register('message')} required autoComplete="off" />
        </fieldset>
        <button type="submit">Encrypt</button>
      </form>
    </fieldset>
  )
}
