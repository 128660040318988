import { AutoSubmitForm } from 'components/AutoSubmitForm'
import { MouseEvent, useCallback, useState } from 'react'
import { useAppConfig } from '../../../context/AppConfigContext'

type LoginRequestResponse = { type: string; context: string; entityEndpoint: string }

export const SamlPostLogin = () => {
  const [data, setData] = useState<LoginRequestResponse>()
  const {
    config: { samlIpLoginUrl },
  } = useAppConfig()

  const handlePostLogin = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    const response = await fetch(samlIpLoginUrl, {
      method: 'POST',
    })

    setData((await response.json()) as LoginRequestResponse)
  }

  const resetData = useCallback(() => {
    setData(undefined)
  }, [])

  return (
    <>
      <a onClick={handlePostLogin} href={''}>
        SAML post login
      </a>
      {data && (
        <AutoSubmitForm actionUrl={data.entityEndpoint} params={{ [data.type]: data.context }} onSubmit={resetData} />
      )}
    </>
  )
}
