import { FC } from 'react'

type Props = {
  error: string
  description?: string | null
}

export const OidcError: FC<Props> = ({ error, description }) => {
  return (
    <>
      <div>
        <strong>Error:</strong>
        <pre>{error}</pre>
      </div>
      {description && (
        <div>
          <strong>Description:</strong>
          <pre>{description}</pre>
        </div>
      )}
    </>
  )
}
