import { useQuery } from 'hook/useQuery'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../constants'

export const SamlResponse: FC = () => {
  const search = useQuery()

  return (
    <>
      <div>user: {decodeURI(search.get('data') || '')}</div>
      <div>
        attributes: <pre>{JSON.stringify(JSON.parse(decodeURI(search.get('attributes') || '')), null, 2)}</pre>
      </div>
      <Link to={ROUTES.index}>Back to login</Link>
    </>
  )
}
