import { useEffect, useRef } from 'react'

type AutoSubmitFormProps = { actionUrl: string; params: Record<string, string>; onSubmit?: () => void }

export const AutoSubmitForm = ({ actionUrl, params, onSubmit }: AutoSubmitFormProps) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()

      if (onSubmit) {
        onSubmit()
      }
    }
  }, [onSubmit])

  return (
    <form ref={formRef} method="POST" action={actionUrl} target="_blank">
      {Object.keys(params).map((name) => (
        <input type="hidden" name={name} id={name} value={params[name]} />
      ))}
    </form>
  )
}
