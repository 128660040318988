import { getToken } from 'api/oidc'
import { GetTokenResponse } from 'api/types'
import { ClaimDownloadConfigurator } from 'components/ClaimDownloadConfigurator'
import { CopyToClipboardButton } from 'components/CopyToClipboardButton'
import { Details, DetailsContent, DetailsSummary } from 'components/Details'
import { DisplayObject } from 'components/DisplayObject'
import { Divider } from 'components/Divider'
import { OidcError } from 'components/OidcError'
import { RefreshTokenConfigurator } from 'components/RefreshTokenConfigurator'
import { Code, Pre } from 'components/SelectableText'
import { TokenExchangeConfigurator } from 'components/TokenExchangeConfigurator'
import { useOidcConfiguration } from 'hook/useOidcConfiguration'
import { useQuery } from 'hook/useQuery'
import { JwtPayload } from 'jwt-decode'
import { compactMap, isString } from 'oneid-common'
import { useEffect, useState } from 'react'
import { parseJwt } from 'utils/jwt'
import { extractClaimFromDownloadUrl, getStateDetails, getTokenParams } from 'utils/oidc'
import { OneKeyDecryption } from '../../components/OneKeyDecryption'
import { OneKeyEncryption } from '../../components/OneKeyEncryption'
import { dbSeedClients } from '../../constants'

interface AccessTokenJwtPayload extends JwtPayload {
  one_key?: string
}

interface IdTokenJwtPayload extends JwtPayload {
  sub: string
  one_key?: {
    public: string
    alg: string
  }
}

export const RedirectHandler = () => {
  const { configuration, loading, error } = useOidcConfiguration()
  const [tokenResponse, setTokenResponse] = useState<GetTokenResponse | null>(null)
  const [exchangeToken, setExchangeToken] = useState<string | null>(null)
  const [cipherTextBase64, setCipherTextBase64] = useState<string>('')
  const query = useQuery()

  useEffect(() => {
    const asyncFetchIdToken = async () => {
      if (tokenResponse || !query.get('code') || !configuration || loading) {
        return
      }

      const token = await getToken(configuration, getTokenParams(query.get('code')!, query.get('state')!))

      setTokenResponse(token)
    }

    void asyncFetchIdToken()
  }, [query, tokenResponse, configuration, loading])

  if (tokenResponse && 'error' in tokenResponse) {
    return <DisplayObject data={tokenResponse} />
  }

  const idTokenJwt = tokenResponse?.id_token ? parseJwt<IdTokenJwtPayload>(tokenResponse.id_token) : null
  const queryError = query.get('error')
  const clientId = getStateDetails(query.get('state'))?.client_id

  const accessTokenJwt = tokenResponse?.access_token?.includes('.')
    ? parseJwt<AccessTokenJwtPayload>(tokenResponse.access_token)
    : null

  const downloadableClaims = idTokenJwt?.payload
    ? compactMap(Object.values(idTokenJwt?.payload), (value) =>
        isString(value) ? extractClaimFromDownloadUrl(value) : null,
      )
    : []

  return (
    <>
      {!loading && configuration && (
        <>
          {!queryError && (
            <div>
              <div>
                <strong>Code:</strong>
                <pre>{query.get('code')}</pre>
              </div>
              <div>
                <span>
                  <strong>accessToken: </strong>
                  <CopyToClipboardButton content={tokenResponse?.access_token} />
                </span>
                <Pre>{tokenResponse?.access_token}</Pre>
                <span>
                  <strong>scope:</strong> {tokenResponse?.scope}
                </span>
                {accessTokenJwt && (
                  <>
                    <div>
                      <strong>accessToken header:</strong>
                      <pre>{JSON.stringify(accessTokenJwt.header, null, 2)}</pre>
                    </div>
                    <div>
                      <strong>accessToken payload:</strong>
                      <pre>{JSON.stringify(accessTokenJwt.payload, null, 2)}</pre>
                    </div>
                  </>
                )}
              </div>
              {!!tokenResponse?.refresh_token && (
                <div>
                  <strong>refreshToken:</strong> <CopyToClipboardButton content={tokenResponse?.refresh_token} />{' '}
                  <Code>{tokenResponse?.refresh_token}</Code>
                </div>
              )}
              <div>
                <strong>idToken header:</strong>
                <pre>{idTokenJwt && JSON.stringify(idTokenJwt.header, null, 2)}</pre>
              </div>
              <div>
                <strong>idToken payload:</strong>
                <pre>{idTokenJwt && JSON.stringify(idTokenJwt.payload, null, 2)}</pre>
              </div>
              <div>
                <strong>state:</strong>
                <pre>{query.get('state')}</pre>
              </div>
            </div>
          )}
          {queryError && <OidcError error={queryError} description={query.get('error_description')} />}
        </>
      )}
      {loading && 'Loading configuration'}
      {error}
      <Divider />
      {configuration && tokenResponse && (
        <Details>
          <DetailsSummary>Token exchange</DetailsSummary>
          <h4>Exchange the "exchange token" for the new access token</h4>
          <DetailsContent>
            <TokenExchangeConfigurator
              configuration={configuration}
              subjectToken={tokenResponse.access_token}
              setResultToken={setExchangeToken}
              clientId={clientId}
              audience={[dbSeedClients.foo]}
              resource={[configuration.token_endpoint]}
            />
          </DetailsContent>
          {exchangeToken && (
            <>
              <h4>Exchange the "exchange token" for the new access token</h4>
              <DetailsContent>
                <TokenExchangeConfigurator
                  configuration={configuration}
                  subjectToken={exchangeToken}
                  clientId={dbSeedClients.foo}
                  resource={[configuration.authorization_endpoint]}
                />
              </DetailsContent>
            </>
          )}
        </Details>
      )}
      {configuration && (
        <Details>
          <DetailsSummary>Refresh token</DetailsSummary>
          <DetailsContent>
            <RefreshTokenConfigurator
              configuration={configuration}
              refreshToken={tokenResponse?.refresh_token}
              clientId={clientId}
            />
          </DetailsContent>
        </Details>
      )}
      {
        <Details>
          <DetailsSummary>OneKey</DetailsSummary>
          <h4>encryption / decryption</h4>
          <DetailsContent>
            <OneKeyEncryption
              sub={idTokenJwt?.payload?.sub}
              publicKeyBase64={idTokenJwt?.payload?.one_key?.public}
              onEncrypt={setCipherTextBase64}
            />
            <OneKeyDecryption accessToken={tokenResponse?.access_token} cipherTextBase64={cipherTextBase64} />
          </DetailsContent>
        </Details>
      }
      {configuration && tokenResponse && (
        <Details>
          <DetailsSummary>Download claim</DetailsSummary>
          <DetailsContent>
            <ClaimDownloadConfigurator
              configuration={configuration}
              claims={downloadableClaims}
              token={tokenResponse?.access_token}
            />
          </DetailsContent>
        </Details>
      )}
    </>
  )
}
