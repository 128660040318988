import { useEffect } from 'react'
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form'

export const useUpdateFormValueOnChange = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>(
  name: TName,
  value: PathValue<TFieldValues, TName>,
  setValue: UseFormSetValue<TFieldValues>,
) => {
  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [name, value, setValue])
}
