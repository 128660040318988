import { useCallback, useState } from 'react'
import { FieldValues, SubmitHandler } from 'react-hook-form'
import { useUpdatedRef } from './useUpdatedRef'

export const useSubmitAction = <T extends FieldValues>(
  action: SubmitHandler<T>,
  onError?: (message: string) => void,
): [SubmitHandler<T>, boolean, string | null] => {
  const [isInProgress, setIsInProgress] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const isInProgressRef = useUpdatedRef(isInProgress)

  const runAction = useCallback(
    async (...args: Parameters<SubmitHandler<T>>) => {
      if (isInProgressRef.current) return

      setIsInProgress(true)
      try {
        return await action(...args)
      } catch (e: unknown) {
        const err = e as Record<string, string>
        const errorMessage = 'message' in err ? err.message : 'Action failed'
        setError(errorMessage)
        onError && onError(errorMessage)
      } finally {
        setIsInProgress(false)
      }
    },
    [action, isInProgressRef, onError],
  )

  return [runAction, isInProgress, error]
}
