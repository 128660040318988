import { tokenIntrospection } from 'api/oidc'
import { Oauth2ErrorResponse, TokenIntrospectionSuccessResponse } from 'api/types'
import { Creatable } from 'components/Creatable'
import { DisplayObject } from 'components/DisplayObject'
import { Input } from 'components/Input'
import { useSubmitAction } from 'hook/useSubmitAction'
import { useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Metadata } from 'types/oidc'
import { Option } from 'types/select'
import { unixTimestampToString } from 'utils/converters'
import { toCreatableOptions, toOption, toValue } from 'utils/select'
import { displayErrorToast, displaySuccessToast } from 'utils/toasts'
import { dbSeedClients } from '../constants'

type ConfiguratorData = {
  clientId: Option<string>
  clientSecret?: string
  token: string
  tokenHint?: Option<string>
}

const toHumanReadable = ({ exp, iat, nbf, ...rest }: TokenIntrospectionSuccessResponse) => ({
  ...rest,
  ...(exp !== undefined ? { exp: unixTimestampToString(exp) } : {}),
  ...(iat !== undefined ? { iat: unixTimestampToString(iat) } : {}),
  ...(nbf !== undefined ? { nbf: unixTimestampToString(nbf) } : {}),
})

export const TokenIntrospectionConfigurator = ({
  configuration,
  clientId,
}: {
  configuration: Metadata
  clientId?: string
}) => {
  const { control, register, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      clientId: toOption(clientId ?? dbSeedClients.foo),
      clientSecret: '',
      token: '',
    },
  })

  const [result, setResult] = useState<ReturnType<typeof toHumanReadable> | Oauth2ErrorResponse | null>(null)
  const fetchTokenInfo = useCallback<SubmitHandler<ConfiguratorData>>(
    async (values) => {
      const res = await tokenIntrospection(configuration, {
        client_id: toValue(values.clientId),
        client_secret: values.clientSecret,
        token: values.token,
        token_type_hint: values.tokenHint && toValue(values.tokenHint),
      })

      if ('error' in res) {
        displayErrorToast('Received error response!')
        setResult(res)
      } else {
        displaySuccessToast()
        setResult(toHumanReadable(res))
      }
    },
    [configuration],
  )
  const [onSubmit, isSubmitting] = useSubmitAction<ConfiguratorData>(fetchTokenInfo, displayErrorToast)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Client</legend>
          <Controller
            name="clientId"
            control={control}
            render={({ field }) => <Creatable {...field} options={toCreatableOptions(Object.values(dbSeedClients))} />}
            rules={{ required: true }}
          />
        </fieldset>
        <fieldset>
          <legend>Client secret</legend>
          <Input type="text" {...register('clientSecret', { required: false })} autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Token</legend>
          <Input type="text" {...register('token', { required: true })} autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Token hint</legend>
          <Controller
            name="tokenHint"
            control={control}
            render={({ field }) => (
              <Creatable {...field} options={toCreatableOptions(['access_token', 'refresh_token'])} isClearable />
            )}
          />
        </fieldset>
        <fieldset>
          <legend>Actions</legend>
          <button type="submit" disabled={isSubmitting}>
            Token Introspection
          </button>
        </fieldset>
      </form>
      <hr />
      {result && (
        <div>
          <DisplayObject data={result} />
        </div>
      )}
    </>
  )
}
