import { createContext, useEffect, useState } from 'react'
import { FCC } from 'types/react'
import { useDefinedContext } from '../utils/useDefinedContext'

export type AppConfig = { oidcProviderUrl: string; samlIpLoginUrl: string; oneKeyHkdfPrefix: string }

type AppConfigContextProps = {
  config: AppConfig
}

const fetchConfig = async (): Promise<AppConfig> => {
  return (await fetch('config.json').then((response) => response.json())) as AppConfig
}

export const AppConfigContext = createContext<AppConfigContextProps | undefined>(undefined)

export const AppConfigProvider: FCC = ({ children }) => {
  const [appConfig, setAppConfig] = useState<AppConfig>()
  const [error, setError] = useState<boolean>()

  useEffect(() => {
    const load = async () => {
      try {
        setAppConfig(await fetchConfig())
      } catch {
        setError(true)
      }
    }
    void load()
  }, [])

  if (!appConfig) {
    return <div>Loading application configuration</div>
  }

  if (error) {
    return <div>Cannot load application configuration</div>
  }

  return <AppConfigContext.Provider value={{ config: appConfig }}>{children}</AppConfigContext.Provider>
}

export const useAppConfig = () => {
  return useDefinedContext(AppConfigContext)
}
