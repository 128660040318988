export const toPairs = (obj: { [key in string]?: string | string[] }): string[][] => {
  return Object.entries(obj).flatMap(([key, value]) => {
    if (value === undefined) {
      return []
    } else if (typeof value === 'string') {
      return [[key, value]]
    }
    return value.map((_value) => [key, _value])
  })
}
