import { exchangeToken } from 'api/oidc'
import { ExchangeTokenAnyResponse } from 'api/types'
import { Creatable } from 'components/Creatable'
import { DisplayObject } from 'components/DisplayObject'
import { Input } from 'components/Input'
import { OidcError } from 'components/OidcError'
import { useSubmitAction } from 'hook/useSubmitAction'
import { useCallback, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Metadata } from 'types/oidc'
import { Option, Options } from 'types/select'
import { getConfiguredResources } from 'utils/configuration'
import { toCreatableOptions, toOption, toOptions, toValue, toValues } from 'utils/select'
import { displayErrorToast, displaySuccessToast } from 'utils/toasts'
import { dbSeedClients } from '../constants'

type ConfiguratorData = {
  clientId: Option<string>
  subjectToken?: string
  resource: Options<string[]>
  audience: Options<string[]>
}

export const TokenExchangeConfigurator = ({
  configuration,
  setResultToken,
  subjectToken,
  clientId,
  audience,
  resource,
}: {
  configuration: Metadata
  setResultToken?: (token: string) => void
  subjectToken?: string
  clientId?: string
  audience?: string[]
  resource?: string[]
}) => {
  const { control, register, setValue, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      clientId: toOption(clientId ?? dbSeedClients.teEnabled),
      audience: toOptions(audience ?? []),
      resource: toOptions(resource ?? [configuration.token_endpoint]),
      subjectToken,
    },
  })

  useEffect(() => {
    if (subjectToken) {
      setValue('subjectToken', subjectToken)
    }
  }, [subjectToken, setValue])

  const [result, setResult] = useState<ExchangeTokenAnyResponse | null>(null)
  const fetchExchangeToken = useCallback<SubmitHandler<ConfiguratorData>>(
    async (values) => {
      const res = await exchangeToken(configuration, {
        client_id: toValue(values.clientId),
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        audience: toValues(values.audience),
        resource: toValues(values.resource),
        subject_token: values.subjectToken,
        subject_token_type: 'urn:ietf:params:oauth:token-type:access_token',
        requested_token_type: undefined,
      })

      setResult(res)
      if ('error' in res) {
        displayErrorToast('Received error response!')
      } else {
        displaySuccessToast()
        setResultToken && setResultToken(res.access_token)
      }
    },
    [configuration, setResultToken],
  )
  const [onSubmit, isSubmitting] = useSubmitAction<ConfiguratorData>(fetchExchangeToken, displayErrorToast)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Client</legend>
          <Controller
            name="clientId"
            control={control}
            render={({ field }) => <Creatable {...field} options={toCreatableOptions(Object.values(dbSeedClients))} />}
          />
        </fieldset>
        <fieldset>
          <legend>Audience (target client ID)</legend>
          <Controller
            name="audience"
            control={control}
            render={({ field }) => (
              <Creatable {...field} options={toCreatableOptions(Object.values(dbSeedClients))} isMulti isClearable />
            )}
          />
        </fieldset>
        <fieldset>
          <legend>Resources</legend>
          <Controller
            name="resource"
            control={control}
            render={({ field }) => (
              <Creatable
                {...field}
                options={toCreatableOptions(getConfiguredResources(configuration))}
                isMulti
                isClearable
              />
            )}
          />
        </fieldset>
        <fieldset>
          <legend>Token to exchange</legend>
          <Input type="text" {...register('subjectToken')} autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Actions</legend>
          <button type="submit" disabled={isSubmitting}>
            Exchange token
          </button>
        </fieldset>
      </form>
      <hr />
      {result && (
        <div>
          {'error' in result ? (
            <OidcError error={result.error} description={result.error_description} />
          ) : (
            <>
              <DisplayObject data={result} copyableProps={['access_token', 'refresh_token']} />
            </>
          )}
        </div>
      )}
    </>
  )
}
