import { SUPPORTED_COUNTRIES, SUPPORTED_DOCUMENT_TYPES } from '../constants'

export const filterSupportedClaim = (claim: string) => {
  const [, , type, country] = claim.split(':')

  if (type && country && SUPPORTED_DOCUMENT_TYPES.includes(type)) {
    return SUPPORTED_COUNTRIES.includes(country)
  }

  return true
}

export const filterSupportedScopes = (scope: string) => {
  const [type, country] = scope.split('-')

  if (type && country) {
    return SUPPORTED_DOCUMENT_TYPES.includes(type) && (SUPPORTED_COUNTRIES.includes(country) || country === '*')
  }

  return true
}
