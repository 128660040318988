import { generatePath, matchPath } from 'react-router-dom'
import { CodeChallengeMethodsSupported, ResponseTypesScopes } from 'types/oidc'

type OidcCustomParameters = {
  accessToken?: string
  description?: string
  consentType?: string
  itemsToSign?: string
}

type OidcAuthParameters = {
  prompt?: string[]
  type?: ResponseTypesScopes
  challenge?: CodeChallengeMethodsSupported
  scopes: string[]
  claims?: string[]
  returnUrl: string
  clientId: string
  resources?: string[]
} & OidcCustomParameters

const CODE_CHALLENGE = '12345678901234567890123456789012345678901234567890'
const STATE_SEPARATOR = '___'

const getAuthUrlParameters = ({
  clientId,
  scopes,
  challenge = 'plain',
  claims,
  returnUrl,
  resources,
  accessToken,
  description,
  consentType,
  itemsToSign,
  type = 'code',
  prompt,
}: OidcAuthParameters) => {
  const queryParams = new URLSearchParams([
    ...Object.entries({
      client_id: clientId,
      response_type: type,
      redirect_uri: returnUrl,
      scope: scopes.join(' '),
      nonce: 'foobar',
      ...(prompt ? { prompt: prompt.join(' ') } : {}),
      code_challenge: CODE_CHALLENGE,
      code_challenge_method: challenge,
      state: `${returnUrl}${STATE_SEPARATOR}${clientId}`, // simple way to share params between /auth and /token requests. Cannot be used in real RP app!!
      ...(accessToken ? { token: accessToken } : {}),
    }),
    ...(resources ? resources.map((r) => ['resource', r]) : []),
  ])

  if (claims) {
    queryParams.set(
      'claims',
      JSON.stringify({
        id_token: Object.fromEntries(claims.map((claim) => [claim, null])),
      }),
    )
  }

  if (description) {
    queryParams.set('description', description)
  }

  if (consentType) {
    queryParams.set('consentType', consentType)
  }

  if (itemsToSign) {
    queryParams.set('itemsToSign', itemsToSign)
  }

  return queryParams.toString()
}

export const getAuthUrl = (urlBase: string, parameters: OidcAuthParameters) => {
  const url = new URL(urlBase)

  url.search = getAuthUrlParameters(parameters)

  return url
}

export const getTokenParams = (code: string, state: string) => {
  const [redirect_uri, client_id] = state.split(STATE_SEPARATOR)
  return {
    code,
    grant_type: 'authorization_code',
    client_id,
    redirect_uri,
    code_verifier: CODE_CHALLENGE,
  }
}

export const getStateDetails = (state: string | null) => {
  if (!state) {
    return undefined
  }
  const [redirect_uri, client_id] = state.split(STATE_SEPARATOR)
  return { redirect_uri, client_id }
}

const claimDownloadPath = 'oidc/data/:claim'

export const getClaimDownloadUrl = (issuer: string, claim: string) =>
  new URL(generatePath(claimDownloadPath, { claim }), issuer)

export const extractClaimFromDownloadUrl = (url: string) => {
  try {
    return matchPath(claimDownloadPath, new URL(url).pathname)?.params.claim
  } catch {
    return undefined
  }
}
