import { Option, Options } from 'types/select'

export const toOption = <T extends string | null>(value: T) => ({ value, label: value ?? 'none' })
export const toOptions = <T extends string | null>(values: T[]) => values.map(toOption)
export const toValue = <T>(option: Option<T>) => option.value
export const toValues = <T>(options: Options<T[]>) => options.map(toValue)

export const creatableExplanationOption = {
  value: 'creatableSpecialExplanationValue',
  label: 'or type in any other value',
  isDisabled: true,
}

export const toCreatableOptions = <T extends string | null>(values: T[]) => [
  ...toOptions(values),
  creatableExplanationOption,
]
