import { claimDownload } from 'api/oidc'
import { OneIdErrorResponse } from 'api/types'
import { Input } from 'components/Input'
import { useSubmitAction } from 'hook/useSubmitAction'
import { useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Metadata } from 'types/oidc'
import { Option } from 'types/select'
import { toCreatableOptions, toOption, toOptions, toValue } from 'utils/select'
import { displayErrorToast, displaySuccessToast } from 'utils/toasts'
import { SUPPORTED_COUNTRIES } from '../constants'
import { Creatable } from './Creatable'
import { DisplayObject } from './DisplayObject'

type ConfiguratorData = {
  claim: Option<string>
  token?: string
}

type ImageResult = {
  imageUrl: string
}

type ErrorResult = {
  error: OneIdErrorResponse
}

const defaultClaims = [
  'urn:oneid:id:bhr:photo:front',
  'urn:oneid:id:bhr:photo:back',
  'liveness_photo',
  ...SUPPORTED_COUNTRIES.map((country) => `urn:oneid:passport:${country}:photo:front`),
]

export const ClaimDownloadConfigurator = ({
  configuration,
  token,
  claims = [],
}: {
  configuration: Metadata
  claims?: string[]
  token?: string
}) => {
  const filteredDefaultClaims = defaultClaims.filter((claim) => !claims.includes(claim))

  const { control, getValues, register, handleSubmit } = useForm<ConfiguratorData>({
    defaultValues: {
      claim: toOption(claims[0] ?? defaultClaims[0]),
      token: token ?? '',
    },
  })

  const currentValues = getValues()

  const [result, setResult] = useState<ImageResult | ErrorResult | null>(null)
  const fetchClaim = useCallback<SubmitHandler<ConfiguratorData>>(
    async (values) => {
      if (result && 'imageUrl' in result) {
        URL.revokeObjectURL(result.imageUrl)
      }

      const res = await claimDownload(configuration, toValue(values.claim), values.token)
      if (!res.ok) {
        displayErrorToast(res.statusText)
        setResult({ error: (await res.json()) as OneIdErrorResponse })
        return
      }

      const contentType = res.headers.get('content-type')
      if (contentType?.includes('image/jpeg')) {
        displaySuccessToast()
        setResult({ imageUrl: URL.createObjectURL(await res.blob()) })
      } else {
        displayErrorToast(`Unsupported context type ${contentType}`)
        setResult(null)
      }
    },
    [configuration, result],
  )
  const [onSubmit, isSubmitting] = useSubmitAction<ConfiguratorData>(fetchClaim, displayErrorToast)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>Claim</legend>
          <Controller
            name="claim"
            control={control}
            render={({ field }) => (
              <Creatable
                {...field}
                options={[
                  {
                    label: 'ID token',
                    options: toOptions(claims),
                  },
                  {
                    label: 'Default',
                    options: toCreatableOptions(filteredDefaultClaims),
                  },
                ]}
              />
            )}
            rules={{ required: true }}
          />
        </fieldset>
        <fieldset>
          <legend>Access Token</legend>
          <Input type="text" {...register('token', { required: false })} autoComplete="off" />
        </fieldset>
        <fieldset>
          <legend>Actions</legend>
          <button type="submit" disabled={isSubmitting}>
            Download claim
          </button>
        </fieldset>
      </form>
      <hr />
      {result && 'imageUrl' in result && (
        <img src={result.imageUrl} alt={`Image for ${toValue(currentValues.claim) || 'no claim'}`} />
      )}
      {result && 'error' in result && <DisplayObject data={result.error} />}
    </>
  )
}
