export const SUPPORTED_COUNTRIES = ['cze', 'dnk', 'bhr', 'rus']
export const SUPPORTED_DOCUMENT_TYPES = ['id', 'passport']

export const ROUTES = {
  index: '/',
  redirectHandler: '/test',
  samlResult: '/saml-result',
  tokenExchange: '/token-exchange',
  tokenIntrospection: '/token-introspection',
  downloadClaim: '/download-claim',
} as const

export const dbSeedClients = {
  foo: 'foo',
  bar: 'bar',
  teEnabled: 'te-enabled',
  oneidSign: 'oneid-sign',
}
